import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="footer">
      <p className="text">josephperkins1999@gmail.com</p>
    </div>
  );
}
