import React from "react";
import "./Header.css";

export default function Header() {
  return (
    <div className="header">
      <p className="text">Joseph Perkins</p>
    </div>
  );
}
